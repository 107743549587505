<template>
    <v-container fluid class="pa-0">

        <v-navigation-drawer app rail permanent location="right" clipped>
            <v-list density="compact">
                <v-list-item class="px-2" >
                    <v-tooltip location="bottom">
                        <template v-slot:activator="{ isActive: on, props: attrs }">
                            <v-btn icon="mdi-cube-outline" v-bind="attrs" v-on="{on}"  @click.stop="addAquariumDialog = true">
                            </v-btn>
                        </template>
                        <span>Add aquarium</span>
                    </v-tooltip>

                    <v-dialog v-model="addAquariumDialog" persistent max-width="400px">       
                        <v-card>
                            <v-card-title>
                                <span class="headline">New aquarium</span>
                            </v-card-title>
                            <v-card-text>
                                <v-form v-model="addAquariumDialogIsValid">  
                                <v-container>
                                <v-row>
                                    <v-col cols="12">
                                    <v-text-field v-model="newAquarium.Name" label="Name*" required :rules="[v => !!v || 'Name is required']"></v-text-field>
                                    </v-col>
                                </v-row>
                                </v-container>
                                </v-form>
                                <small>*indicates required field</small>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" variant="text" @click="cancelAquariumCreate">Cancel</v-btn>
                                <v-btn color="primary" variant="text" @click="commitAquariumCreate" :disabled="!addAquariumDialogIsValid">Create</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-list class="ma-0 pa-0" color="transparent" v-if="aquariums">
            <v-card class="ma-2" variant="outlined" shaped v-for="aquarium in aquariums" v-bind:key="aquarium.Id">
                <v-card-title>{{aquarium.Name}} 
                </v-card-title>
                <v-card-subtitle>Created: {{aquarium.CreatedTimestamp}}</v-card-subtitle>

                <v-card-text class="d-flex flex-nowrap">

                    <v-list v-for="device in aquarium.Devices" v-bind:key="device.Id" >

                        <v-card class="ma-1" variant="tonal" v-if="device.LastSample" >
                            <v-card-text class="pa-2">
                                <v-icon>mdi-link-variant</v-icon> {{device.Name}} Activity {{moment(device.LastSample.TimeStamp).fromNow()}}
                                <v-chip class="ma-1 text-caption" variant="outlined" label v-bind:color="propertyColor(sample.Alert)" v-for="sample in restrictedProperties(device.LastSample.Value)" v-bind:key="sample.PropertyId" >
                                    <v-avatar location="left">
                                        <v-icon v-bind:color="propertyColor(sample.Alert)">{{propertyIcon(sample.PropertyId)}}</v-icon>
                                    </v-avatar>{{propertyDescription(sample.PropertyId)}}: {{sample.Value}}{{propertyUnit(sample.PropertyId)}}
                                </v-chip>
                            </v-card-text>
                        </v-card>

                        <v-card class="ma-1" variant="outlined" v-if="!device.LastSample" >
                            <v-card-text class="pa-2"> 
                                <v-icon>mdi-link-variant</v-icon> {{device.Name}} No recent activity
                            </v-card-text>
                        </v-card>

                    </v-list>

                    <v-card v-if="aquarium.PendingNewDevice" class="ma-1" color="error">
                        <v-card-text class="pa-2"> 
                            <v-icon>mdi-exclamation</v-icon>Pending device association
                        </v-card-text>
                    </v-card>


                </v-card-text>
                <v-card-actions>
                    <v-btn variant="text" link color="primary" :to="'/aquariums/' + aquarium.Id">
                        Details
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-list>

    </v-container>
</template>

<script>

import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'Home-Item',
    data: function() {
        return {
            addAquariumDialog: false,
            addAquariumDialogIsValid: true,
            newAquarium:{
                Name: undefined
            },
            timer: null  
        }
    },
    computed: {
      ...mapGetters(['aquariums', 'userInfo', 'loading', 'deviceProperties'])
    },
    methods:{
        propertyDescription(propertyName)
        {
            for (let index = 0; index < this.deviceProperties.length; index++) 
            {
                const element = this.deviceProperties[index];      
                if(element.Id == propertyName)
                    return element.Description;  
            }
            return propertyName;
        },
        propertyIcon(propertyName)
        {
            console.log("propertyIcon")
            for (let index = 0; index < this.deviceProperties.length; index++) 
            {
                const element = this.deviceProperties[index];      
                if(element.Id == propertyName)
                    return element.Icon;  
            }
            return propertyName;
        },
        propertyUnit(propertyName)
        {
            for (let index = 0; index < this.deviceProperties.length; index++) 
            {
                const element = this.deviceProperties[index];      
                if(element.Id == propertyName)
                    return element.Unit;  
            }
            return propertyName;
        },
        propertyColor(alertLevel)
        {
            if(!alertLevel || alertLevel<1)
            {
                return "success";
            }
            else if (alertLevel<2)
            {
                return "warning";
            }
            else
            {
                return "error";
            }
        },
        restrictedProperties(properties)
        {
            return properties.filter( property => property.PropertyId == "1" || property.PropertyId == "2" );
        },
        commitAquariumCreate(){
            this.addAquariumDialog = false;            
            this.$store.dispatch("createAquarium", {Name: this.newAquarium.Name});
            this.newAquarium.Name  = undefined;
        },
        cancelAquariumCreate(){
            this.addAquariumDialog = false;
            this.newAquarium.Name  = undefined;
        }
    },
    mounted()
    {
        console.log('reloading aquariums from mounted');
        this.$store.dispatch("reloadAquariums");
        this.timer = window.setInterval(() => {
            this.$store.dispatch("reloadAquariums" )
        }, 60000)
    },
    beforeUnmount() {
        clearInterval(this.timer)
    },
    created: function () {
        this.moment = moment;
    },
}
</script>
<style>
</style>