// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
// Vuetify
import { createVuetify } from 'vuetify'
import { loadFonts } from './webfontloader'
import colors from 'vuetify/lib/util/colors'

loadFonts()

const myCustomTheme = {
    dark:true,
    colors: {          
      primary: colors.blue.darken1,//'#1e88e5',
      secondary:  colors.cyan.base,
      accent: colors.shades.black,
      error: colors.red.darken3,
      success: colors.lightGreen.darken1,
      warning: colors.orange.base
    }
  }

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'myCustomTheme',
    themes: {
      myCustomTheme,
    }
  },
})
