<template>
<v-container fluid>  
        <v-card v-if="updatedSettings">
            <v-card-title>
                <span class="headline">User settings</span>
            </v-card-title>
            <v-card-text>
                <v-form v-model="updateSettingsIsValid">  
                <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="updatedSettings.Phone" label="Phone*" required :rules="[v => !!v || 'Phone is required']"></v-text-field>
                    </v-col>
                </v-row>
                </v-container>
                </v-form>
                <small>*indicates required field</small>
                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="text" @click="fillUpdatedSettings">Cancel</v-btn>
                <v-btn color="primary" variant="text" @click="commitSettingsUpdate" :disabled="!updateSettingsIsValid">Update</v-btn>
            </v-card-actions>
        </v-card>    

</v-container>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
    name: 'Settings-Item',
    data: function() {
        return {
            updateSettingsIsValid: true,
            updatedSettings: undefined
        }
    },
    computed: {
        ...mapGetters(['settings'])
    },
    methods:{
        commitSettingsUpdate(){
            this.$store.dispatch("updateSettings", this.updatedSettings);
        },
        fillUpdatedSettings()
        {
            if(this.settings)
            {
                this.updatedSettings = { 
                    Phone: this.settings.Phone
                }
            }
            else
            {
                this.updatedSettings = { 
                    Phone: ''
                }
            }
        },


    },
    watch:{
        '$store.state.settings': function() {
         this.fillUpdatedSettings();
      }
    },
    mounted()
    {
        this.$store.dispatch("reloadSettings");
        
    }
}
</script>