<template>

    <v-app>
      <v-app-bar app density="compact"  clipped-right >      


        <v-btn>
          <img :src="require('@/assets/logo.png')"/>
        </v-btn>
    
        <p class="mr-10 pl-0" >Reefmon</p>

        <v-tooltip location="bottom">
          <template v-slot:activator="{ isActive: on, props: attrs }">
            <v-btn  prepend-icon="mdi-gauge" v-bind="attrs" v-on="{on}" link to="/aquariums" @click.stop="addAquariumDialog = true">
                Dashboard
            </v-btn>
          </template>
          <span>Dashboard</span>
        </v-tooltip>

        <v-spacer></v-spacer>
        
        <v-progress-linear :indeterminate="true" :query="true" v-bind:active="loading" bottom absolute location="bottom"></v-progress-linear>

        <v-menu location="left bottom"  >
          <template v-slot:activator="{ isActive: on, props: attrs }">
            <v-btn icon="mdi-dots-vertical"  v-bind="attrs"  v-on="{on}" location="right">
            </v-btn>
          </template>

          <v-list>
            <v-list-item link to="/settings" icon="mdi-hammer-wrench"> 
                <v-list-item-title class="title">{{userName}}</v-list-item-title>
                <v-list-item-subtitle>{{userEmail}}</v-list-item-subtitle>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list nav density="compact">
            <v-list-item link to="/logout" icon="mdi-logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>


      </v-app-bar>

    <v-main>
      <v-container fluid class="pa-0">
        <router-view/>
      </v-container>
    </v-main>

    <v-footer app class="d-flex flex-column">
      <div class="align-center">
        <p class="ma-0 text-center align-center caption">Reefmon &copy;2020</p>
        </div>
    </v-footer>

  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'


export default {
  name: 'app',
    data: function() {
      return {
        drawer: true,
        dialog: false,
        isValid: true,
        newAquarium:{
          Name: undefined
        }
      }
    },
    computed: {
      userName:{
        get: function(){
          if(this.userInfo)
            return this.userInfo.username;
          else return undefined;
        }
      },
      userEmail:{
        get: function(){
          if(this.userInfo)
            return this.userInfo.email;
          else return undefined;
        }
      },
      ...mapGetters(['userInfo','loading'])
    },
    methods:
    {
    },
    mounted()
    {            
    }  
}
</script>

