<template>
    <v-container fluid class="pa-0">

        <v-navigation-drawer app rail permanent location="right" clipped>
            <v-list density="compact">
                <v-list-item class="px-2">

                    <v-tooltip location="bottom">
                        <template v-slot:activator="{ isActive: on, props: attrs }">
                            <v-btn icon="mdi-cube-scan" v-bind="attrs" v-on="{on}"  @click.stop="fillUpdatedAquarium(); updateAquariumDialog = true;">
                            </v-btn>
                        </template>
                        <span>Modify aquarium</span>
                    </v-tooltip>

                    <v-dialog v-model="updateAquariumDialog" persistent max-width="400px">       
                        <v-card>
                            <v-card-title>
                                <span class="headline">Modify aquarium</span>
                            </v-card-title>
                            <v-card-text v-if="updatedAquarium">
                                <v-form v-model="updateAquariumDialogIsValid">  
                                <v-container>
                                <v-row>
                                    <v-col cols="12">
                                    <v-text-field v-model="updatedAquarium.Name" label="Name*" required :rules="[v => !!v || 'Name is required']"></v-text-field>
                                    </v-col>
                                </v-row>
                                </v-container>
                                </v-form>
                                <small>*indicates required field</small>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" variant="text" @click="cancelAquariumUpdate">Cancel</v-btn>
                                <v-btn color="primary" variant="text" @click="commitAquariumUpdate" :disabled="!updateAquariumDialogIsValid">Update</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-list-item>

                <v-list-item class="px-2">

                    <v-tooltip location="bottom">
                        <template v-slot:activator="{ isActive: on, props: attrs }">
                            <v-btn icon="mdi-water-plus-outline" v-bind="attrs" v-on="{on}" @click="aquariumGetAssociationPinCode" >
                            </v-btn>
                        </template>
                        <span>Add device</span>
                    </v-tooltip>

                </v-list-item>

                <v-divider></v-divider>

                <v-list-item class="px-2">

                    <v-tooltip location="bottom">
                        <template v-slot:activator="{ isActive: on, props: attrs }">
                            <v-btn icon="mdi-cube-off-outline" v-bind="attrs" v-on="{on}" @click="deleteAquariumDialog=true" >
                            </v-btn>
                        </template>
                        <span>Delete aquarium</span>
                    </v-tooltip>

                    <v-dialog v-model="deleteAquariumDialog" persistent max-width="400px">       
                        
                        <v-card>
                            <v-card-title>
                                <span class="headline">Confirmation</span>
                            </v-card-title>
                            <v-card-text v-if="aquarium" >                                
                                Are you sure you ant to delete aquarium {{aquarium.Name}} and all its associated devices?                                
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" variant="text" @click="deleteAquariumDialog=false;aquariumDelete()">Yes</v-btn>
                                <v-btn color="primary" variant="text" @click="deleteAquariumDialog=false">No</v-btn>
                            </v-card-actions>
                        </v-card>
                        
                    </v-dialog>


                </v-list-item>


                <v-list-item class="px-2">
                    <v-tooltip location="bottom">
                        <template v-slot:activator="{ isActive: on, props: attrs }">
                            <v-btn v-bind="attrs" v-on="{on}" icon="mdi-refresh-circle" @click="refresh()" >
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                </v-list-item>

            </v-list>
        </v-navigation-drawer>


        <v-card variant="outlined" shaped class="ma-2" v-if="aquariums && aquarium"  >
            <v-card-title>{{aquarium.Name}}</v-card-title>

            <v-card-subtitle>Created: {{moment(aquarium.CreatedTimestamp).local()}}</v-card-subtitle>

            <v-card-text>

                    <v-list v-for="device in aquarium.Devices" v-bind:key="device.Id" style="background:transparent;" >
                        
                        <v-card variant="tonal" class="ma-1"  v-if="device.LastSample"   >
                            <v-card-text>
                                <v-icon >mdi-link-variant</v-icon>{{device.Name}} Activity {{moment(device.LastSample.TimeStamp).fromNow() }}
                                <v-chip class="ma-1 text-caption" variant="outlined" label v-bind:color="propertyColor(sample.Alert)" v-for="sample in restrictedProperties(device.LastSample.Value)" v-bind:key="sample.PropertyId" >
                                    <v-avatar location="left">
                                        <v-icon v-bind:color="propertyColor(sample.Alert)" >{{propertyIcon(sample.PropertyId)}}</v-icon>
                                    </v-avatar>{{propertyDescription(sample.PropertyId)}}: {{sample.Value}}{{propertyUnit(sample.PropertyId)}}                                
                                </v-chip>                                
                            </v-card-text>
                            <v-card-text class="d-flex flex-wrap"  >
                                <div style="position: relative;height: 100%; width: 100%;" class="mt-10" v-if="device.GraphDataCollection">
                                    <Line :options="graphOptions()" :data="device.GraphDataCollection" :style="graphStyle" :width="null" :height="null" ></Line>
                                </div>

                            </v-card-text>

                        </v-card>
                        



                        <v-chip class="ma-1 text-caption" large label color="success" v-if="!device.LastSample" >
                            <v-avatar location="left">
                                <v-icon>mdi-link-variant</v-icon>
                            </v-avatar>{{device.Name}}<br/>No recent activity

                        </v-chip>

                    </v-list>


                    <v-chip v-if="aquarium.PendingNewDevice" class="ma-1 text-caption elevation-5" color="error">
                        <v-avatar location="left">
                            <v-icon>mdi-exclamation</v-icon>
                        </v-avatar>
                        Pending device association
                    </v-chip>

            </v-card-text>

            <v-dialog v-model="associationPinCodeAvailable" persistent max-width="400px">       
                <v-card>
                    <v-card-title>
                        <span class="headline">Add new device</span>
                    </v-card-title>
                    <v-card-text>
                        Please enter the following pincode on the device you want to add:
                        <p class="display-1 text--primary text-center">{{aquarium.AssociationPinCode}}</p>         
                        <p class="display-1 text--primary text-center"><v-icon class="mdi-spin mx-auto">mdi-loading</v-icon></p>                                 
                    </v-card-text>


                    <v-divider></v-divider>

                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" variant="text" @click="aquariumCancelAssociationPinCode">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="pendingNewDeviceAvailable" v-if="pendingNewDeviceAvailable" persistent max-width="400px">       
                <v-card>
                    <v-card-title>
                        <span class="headline">Pending device association</span>
                    </v-card-title>

                    <v-card-text >
                        A device (model "{{aquarium.PendingNewDevice.Model}}") with the following characteristics is being associated with this aquarium:   
                        <div class="text-center my-2">
                            <v-chip class="mx-1 my-1 text-caption" color="teal" text-color="white">
                                <v-avatar location="left">
                                    <v-icon>mdi-thermostat</v-icon>
                                </v-avatar>
                                Thermostat
                           </v-chip>
                            <v-chip class="mx-1 my-1 text-caption" color="blue darken-1" text-color="white">
                                <v-avatar location="left">
                                    <v-icon>mdi-thermometer</v-icon>
                                </v-avatar>
                                Water temperature
                            </v-chip>
                            <v-chip class="mx-1 my-1 text-caption" color="blue darken-1" text-color="white">
                                <v-avatar location="left">
                                    <v-icon>mdi-thermometer</v-icon>
                                </v-avatar>
                                Device temperature
                            </v-chip>
                            <v-chip class="mx-1 my-1 text-caption" color="blue darken-1" text-color="white">
                                <v-avatar location="left">
                                    <v-icon>mdi-thermometer</v-icon>
                                </v-avatar>
                                Air temperature
                            </v-chip>
                        </div>
                        Do you want to proceed?

                    </v-card-text >

                    <v-divider></v-divider>

                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" variant="text" @click="confirmPendingNewDevice">Yes</v-btn>
                        <v-btn color="primary" variant="text" @click="cancelPendingNewDevice">No</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="warning" persistent max-width="400px">       
                <v-card>
                    <v-card-title>
                        <span class="headline">Warning</span>
                    </v-card-title>
                    <v-card-text>
                        {{warning}}
                    </v-card-text>


                    <v-divider></v-divider>

                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" variant="text" @click="clearWarning">Ok</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-card>

    </v-container>
</template>
<script>

import 'chartjs-adapter-moment';
import moment from 'moment'
import { mapGetters } from 'vuex'
import { useDisplay } from 'vuetify'
import { Line } from 'vue-chartjs'
import { Chart as ChartJS,  CategoryScale,  LinearScale,  PointElement,  LineElement,  Title,  Tooltip,  Legend, TimeScale, Filler } from 'chart.js'

ChartJS.register( CategoryScale,  LinearScale, TimeScale, PointElement,  LineElement,  Title,  Tooltip,  Legend, Filler);

export default {
    name: 'Aquarium-Item',
    components: {
      Line
    },
    data: function() {
        return {
            updateAquariumDialog: false,
            updateAquariumDialogIsValid: true,
            updatedAquarium: undefined,
            deleteAquariumDialog: false,   
            timer: null
        }
    },
    computed: {
        ...mapGetters(['aquariums','userInfo', 'loading', 'warning', 'deviceProperties']),
        aquarium:{
            get: function(){
                console.log('get aquarium');
                if(this.aquariums)
                {
                    console.log('aquariums ok');
                    for(var i=0; i<this.aquariums.length; i++)
                        if(this.aquariums[i].Id == this.$route.params.id)
                        {
                            return this.aquariums[i];
                        }
                }
                console.log('aquarium not found');
                return undefined;
            }
        },
        associationPinCodeAvailable: function()
        {            
            if(this.aquarium.AssociationPinCode && !this.loading)                
                return true;
            else 
                return false;
        },
        pendingNewDeviceAvailable: function()
        {            
            if(this.aquarium.PendingNewDevice && !this.loading)                
                return true;
            else 
                return false;
        },
        graphStyle: function()
        {
            const { name } = useDisplay();
            console.log(name.value);
            switch (name.value) {
                case 'xs': return {height: "200px"}
                case 'sm': return {height: "200px"}
                case 'md': return {height: "400px"}
                case 'lg': return {height: "400px"}
                case 'xl': return {height: "600px"}
                case 'xxl': return {height: "800px"}
            }
            return {height: "400px"}
        }

    },
    methods:{
        graphOptions()
        {
            console.log("graphOptions")


            return {
                maintainAspectRatio: false,
                responsive: true,
                animation: {
                    duration: 0
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                plugins:{
                legend: {
                    labels: {
                        color: "white",
                        fontSize: 11
                    },
                    position: "bottom"
                }},
                tooltips: {
                    enabled: true,
                    displayColors: false,
                    callbacks: {
                        label: ((tooltipItems) => {
                            if(tooltipItems.datasetIndex<2 )
                            {
                                return tooltipItems.yLabel + '°C'
                            }
                            else if(tooltipItems.datasetIndex<4 )
                            {
                                return tooltipItems.yLabel + '%'
                            }
                            return tooltipItems.yLabel
                            }
                        )
                    }
                },
                scales: {
                    y: {
                        grace:5,
                        ticks: {
                            color: "white",
                            fontSize: 11,
                            autoSkip: true,
                            autoSkipPadding: 10,
                        },
                        gridLines: {
                            display: false
                        },
                        min:0,
                        max:100,
                    },
                    x: {
                        type: 'time',
                        time: {
                            unit:'minute',
                            displayFormats: {
                                minute: 'HH:mm',
                                hour: 'HH:mm'
                            },
                            tooltipFormat: 'HH:mm'
                        },
                        ticks: {                            
                            color: "white",
                            fontSize: 11,
                            autoSkip: true,
                            autoSkipPadding: 10,
                            major:{
                                enabled:true,                                
                            }
                        },
                        grid: {
                            display: true,
                            color: "#FFFFFF11"
                        },
                    }
                }
            };
        },
        propertyDescription(propertyName)
        {
            for (let index = 0; index < this.deviceProperties.length; index++) 
            {
                const element = this.deviceProperties[index];      
                if(element.Id == propertyName)
                    return element.Description;  
            }
            return propertyName;
        },
        propertyIcon(propertyName)
        {
            for (let index = 0; index < this.deviceProperties.length; index++) 
            {
                const element = this.deviceProperties[index];      
                if(element.Id == propertyName)
                    return element.Icon;  
            }
            return propertyName;
        },
        propertyUnit(propertyName)
        {
            for (let index = 0; index < this.deviceProperties.length; index++) 
            {
                const element = this.deviceProperties[index];      
                if(element.Id == propertyName)
                    return element.Unit;  
            }
            return propertyName;
        },
        propertyColor(alertLevel)
        {
            if(!alertLevel || alertLevel<1)
            {
                return "success";
            }
            else if (alertLevel<2)
            {
                return "warning";
            }
            else
            {
                return "error";
            }
        },
        restrictedProperties(properties)
        {
            return properties.filter( property => property.PropertyId == "1" || property.PropertyId == "2" || property.PropertyId == "6" || property.PropertyId == "7" );
        },
        aquariumDelete(){
            this.$store.dispatch("deleteAquarium", this.$route.params.id);
        },
        aquariumGetAssociationPinCode(){
            this.$store.dispatch("generateAquariumAssociationPinCode", this.$route.params.id);
        },
        aquariumCancelAssociationPinCode(){
            this.$store.dispatch("cancelAquariumAssociationPinCode", this.$route.params.id);
        },
        commitAquariumUpdate(){
            this.updateAquariumDialog = false;
            this.$store.dispatch("updateAquarium", this.updatedAquarium);                        
        },
        confirmPendingNewDevice(){
            this.$store.dispatch("confirmPendingNewDevice", this.aquarium );
        },
        cancelPendingNewDevice(){
            this.$store.dispatch("cancelPendingNewDevice", this.aquarium );
        },
        cancelAquariumUpdate(){
            this.updateAquariumDialog = false;
        },
        fillUpdatedAquarium()
        {
            this.updatedAquarium = { 
                Id: this.aquarium.Id,
                Name: this.aquarium.Name
            }
        },
        clearWarning(){
            this.$store.dispatch("clearWarning");
        },

        refresh(){
            this.$store.dispatch("reloadAquarium", this.$route.params.id );
        }


    },
    mounted()
    {
        //this.$store.dispatch("reloadAquariums" ).then(this.$store.dispatch("reloadAquarium", this.$route.params.id ));
        //console.log("reloading " + this.$route.params.id );
        this.$store.dispatch("reloadAquarium", this.$route.params.id );
        
        this.timer = window.setInterval(() => {
            this.$store.dispatch("reloadAquarium", this.$route.params.id )
        }, 60000)

    },
    beforeUnmount() {
        clearInterval(this.timer)
    },
    created: function () {
        this.moment = moment;
    },
}
</script>
<style>
</style>