/* eslint-disable */
import { createStore } from 'vuex'
import axios from 'axios'
import IndexRouter from '../router/index';
import {CognitoAuth} from 'amazon-cognito-auth-js';
import moment from 'moment'

const CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
const APP_DOMAIN = process.env.VUE_APP_COGNITO_APP_DOMAIN;
const REDIRECT_URI = process.env.VUE_APP_COGNITO_REDIRECT_URI;
const USERPOOL_ID = process.env.VUE_APP_COGNITO_USERPOOL_ID;
const REDIRECT_URI_SIGNOUT = process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT;
const APP_URL = process.env.VUE_APP_APP_URL;

var authData = {
    ClientId : CLIENT_ID, 
    AppWebDomain : APP_DOMAIN,
    TokenScopesArray : ['openid', 'email'],
    RedirectUriSignIn : REDIRECT_URI,
    RedirectUriSignOut : REDIRECT_URI_SIGNOUT,
    UserPoolId : USERPOOL_ID,
}

function refreshUserInfo(state)
{
  var jwtToken = state.cognitoAuth.getSignInUserSession().getAccessToken().jwtToken;
  const USERINFO_URL = 'https://'+state.cognitoAuth.getAppWebDomain() + '/oauth2/userInfo';
  var requestData = {
      headers: {
          'Authorization': 'Bearer '+ jwtToken
      }
  }
  axios.get(USERINFO_URL, requestData).then(response => { 
      state.userInfo = response.data;
      console.log("Setting user info: " + response.data)
  })
}

function setUpUserHandlerEvents(state)
{
  if(!state.cognitoAuth.userhandler)  
  {
    console.log("setting up userhanlder")
    state.cognitoAuth.userhandler = {
      onSuccess: function(result)
      {
        console.log("onSuccess")
        if(!state.userInfo)
        {
          refreshUserInfo(state);
        }
      },
      onFailure: function(err)
      {
        IndexRouter.go({ path: '/error', query: { message: 'Login failed due to ' + err } });
      }
    } 
  }
}

const store = createStore({
  state: {
    cognitoAuth: new CognitoAuth(authData),
    userInfo: undefined,
    deviceProperties: undefined,
    aquariums: undefined,
    aquariumsDirty: true,
    loading: false,
    warning: null,
    settings: undefined
  },
  getters:
  {
    cognitoAuth: state => state.cognitoAuth,
    userInfo: state => state.userInfo,
    aquariums: state => state.aquariums,
    deviceProperties: state => state.deviceProperties,
    aquariumsDirty: state => state.aquariumsDirty,
    loading: state => state.loading,
    warning: state => state.warning,
    settings: state => state.settings
  },
  mutations: {
    parseCognitoWebResponse(state, url)
    {
      console.log("parseCognitoWebResponse");
      setUpUserHandlerEvents(state);
      state.cognitoAuth.parseCognitoWebResponse(url);
      IndexRouter.push("/")
    },    
    logout(state)
    {
        if(state.cognitoAuth.isUserSignedIn())
        {
          state.cognitoAuth.signOut();
          state.userInfo = undefined;
          state.aquariums = undefined;    
        }
    },
    setLoading(state, loading)
    {
      state.loading = loading;
    },
    setSettings(state, settings)
    {
      state.settings = settings;
    },
    setAquariums(state, aquariums)
    {
      state.aquariums = aquariums;      
    },
    setDeviceProperties(state, deviceProperties)
    {
      state.deviceProperties = deviceProperties;      
    },
    setAquariumsDirty(state, aquariumsDirty)
    {
      state.aquariumsDirty = aquariumsDirty;
    },
    refreshSession(state)
    {
      state.cognitoAuth.getSession(); 
    },
    setWarning(state, warningMessage)
    {
      state.warning = warningMessage;
    }

  },
  actions: {
    loginOrRefreshSession({commit, state})
    {
      console.log("loginOrRefreshSession")
      setUpUserHandlerEvents(state);
      commit("refreshSession");
    },

    createAquarium({commit, state},aqua)
    {
      commit("setLoading", true)
      commit("refreshSession");

      var jwtToken = state.cognitoAuth.getSignInUserSession().getIdToken().jwtToken;
      var requestData = {
          headers: {
              'Authorization': 'Bearer '+ jwtToken
          }
      }
      axios.post("https://api.reefmon.com/aquariums", aqua, requestData).then(response => {    
        commit('setAquariumsDirty',true) 
        IndexRouter.push("/aquariums/" + response.data)     
      },
      error =>{
        commit("setLoading", false)
        console.log(error)
        if (error.response && error.response.status === 401) {
          commit("refreshSession");
        } 
      }) 
    },

    updateAquarium({commit, state},aqua)
    {
      commit("setLoading", true)
      commit("refreshSession");

      var jwtToken = state.cognitoAuth.getSignInUserSession().getIdToken().jwtToken;
      var requestData = {
          headers: {
              'Authorization': 'Bearer '+ jwtToken
          }
      }
      axios.put("https://api.reefmon.com/aquariums", aqua, requestData).then(response => {  
        this.dispatch("reloadAquarium", aqua.Id)        
      },
      error =>{
        commit("setLoading", false)
        console.log(error)
        if (error.response && error.response.status === 401) {
          commit("refreshSession");
        } 
      }) 
    },

    updateSettings({commit, state},sett)
    {
      commit("setLoading", true)
      commit("refreshSession");

      var jwtToken = state.cognitoAuth.getSignInUserSession().getIdToken().jwtToken;
      var requestData = {
          headers: {
              'Authorization': 'Bearer '+ jwtToken
          }
      }
      axios.put("https://api.reefmon.com/aquariums/user", sett, requestData).then(response => {  
        this.dispatch("reloadSettings")        
      },
      error =>{
        commit("setLoading", false)
        console.log(error)
        if (error.response && error.response.status === 401) {
          commit("refreshSession");
        } 
      }) 
    },

    generateAquariumAssociationPinCode({commit, state},aquaId)
    {
      commit("setLoading", true)
      commit("refreshSession");

      var jwtToken = state.cognitoAuth.getSignInUserSession().getIdToken().jwtToken;
      var requestData = {
          headers: {
              'Authorization': 'Bearer '+ jwtToken
          }
      }
      axios.get("https://api.reefmon.com/aquariums/" + aquaId + "/associationpincode", requestData).then(response => {   
          this.dispatch("reloadAquarium", aquaId) 
        },
        error =>{
        commit("setLoading", false)
        console.log(error)
        if (error.response && error.response.status === 401) {
          commit("refreshSession");
        } 
      }) 
    },

    cancelAquariumAssociationPinCode({commit, state},aquaId)
    {
      commit("setLoading", true)
      commit("refreshSession");

      var jwtToken = state.cognitoAuth.getSignInUserSession().getIdToken().jwtToken;
      var requestData = {
          headers: {
              'Authorization': 'Bearer '+ jwtToken
          }
      }
      axios.delete("https://api.reefmon.com/aquariums/" + aquaId + "/associationpincode", requestData).then(response => {   
        this.dispatch("reloadAquarium", aquaId)  
      },
      error =>{
        commit("setLoading", false)
        console.log(error)
        if (error.response && error.response.status === 401) {
          commit("refreshSession");
        } 
      }) 
    },


    deleteAquarium({commit, state},aquaId)
    {
      commit("setLoading", true)
      commit("refreshSession");

      var jwtToken = state.cognitoAuth.getSignInUserSession().getIdToken().jwtToken;
      var requestData = {
          headers: {
              'Authorization': 'Bearer '+ jwtToken
          }
      }
      axios.delete("https://api.reefmon.com/aquariums?Id=" + aquaId,  requestData).then(response => {         
        commit('setAquariumsDirty',true) 
        IndexRouter.push("/aquariums")
      },
      error =>{
        commit("setLoading", false)
        console.log(error)
        if (error.response && error.response.status === 401) {
          commit("refreshSession");
        } 
      }) 
    },

    reloadSettings({commit, dispatch, state})
    {
      commit("setLoading", true)
      commit("refreshSession");

      var jwtToken = state.cognitoAuth.getSignInUserSession().getIdToken().jwtToken;
      var requestData = {
          headers: {
              'Authorization': 'Bearer '+ jwtToken
          }
      }

      axios.get("https://api.reefmon.com/aquariums/user", requestData).then(response => {         
        if(response.data)
        {
          commit("setSettings", response.data);
        }
        commit("setLoading", false);
      },
      error =>{
        console.log(error)
        if (error.response && error.response.status === 401) {
          commit("setLoading", false)
          commit("refreshSession");
        } 
      }) 
    },

    reloadAquariums({commit, state})
    {
/*      if (!state.aquariumsDirty)
      {
        console.log("skipping reloading aquariums because collection not dirty");
        return;
      }*/

      commit("setLoading", true)
      commit("refreshSession");

      var jwtToken = state.cognitoAuth.getSignInUserSession().getIdToken().jwtToken;
      var requestData = {
          headers: {
              'Authorization': 'Bearer '+ jwtToken
          }
      }

      axios.all([ axios.get("https://api.reefmon.com/aquariums/deviceproperties"),
          axios.get("https://api.reefmon.com/aquariums", requestData)])
      .then(axios.spread((...responses) => {
          commit("setDeviceProperties", responses[0].data);
          console.log("copying old sample");
          if(state.aquariums)
          {
            for(var i=0; i<responses[1].data.length; i++)
            {
              for(var zi =0; zi<state.aquariums.length; zi++)
              {
                if(state.aquariums[zi].Id == responses[1].data[i].Id)
                {
                  for(var j=0; j<responses[1].data[i].Devices.length; j++)
                  {
                    for(var zj=0;zj<state.aquariums[zi].Devices.length; zj++)
                    {
                      if(state.aquariums[zi].Devices[zj].Id == responses[1].data[i].Devices[j].Id)
                      {
                        if(!responses[1].data[i].Devices[j].Samples && state.aquariums[zi].Devices[zj].Samples)
                          responses[1].data[i].Devices[j].Samples = state.aquariums[zi].Devices[zj].Samples;
                        break;
                      }
                    }
                  }  
                  break;
                }
              }
            }
          }
          
          commit("setAquariums", responses[1].data)
          commit("setAquariumsDirty", false)
          commit("setLoading", false)}))
      .catch(errors => {
        commit("setLoading", false)
        console.log(errors)
        if (errors.response && errors.response.status === 401) {
          commit("refreshSession");
        } 
      })
    },

    reloadAquarium({commit, dispatch, state}, aquaId)
    {
      var samplesDuration = moment.duration({'hours':12});      
      console.log("reload aquarium " + aquaId)
      commit("setLoading", true)
      commit("refreshSession");

      var jwtToken = state.cognitoAuth.getSignInUserSession().getIdToken().jwtToken;
      var requestData = {
          headers: {
              'Authorization': 'Bearer '+ jwtToken
          }
      }

      var newestSample = moment().subtract(samplesDuration);
      var startSample = newestSample;

      if(state.aquariums && state.aquariums.length > 0)
      {
        for(var i=0; i<state.aquariums.length;i++)
        {
          if(state.aquariums[i].Id ==aquaId)
          {
            for(var j=0; j<state.aquariums[i].Devices.length; j++)
            {
              var device = state.aquariums[i].Devices[j]
              if(device.Samples)
              {
                newestSample = moment.max(newestSample, moment(device.Samples[device.Samples.length-1].TimeStamp));
              }
            }
          }
        }
      }

      console.log("reloading since " + newestSample.format())        ;

      axios.all([ axios.get("https://api.reefmon.com/aquariums/deviceproperties"),
          axios.get("https://api.reefmon.com/aquariums/" + aquaId  + "?from=" + newestSample.format("X") , requestData)])
      .then(axios.spread((...responses) => {

          commit("setDeviceProperties", responses[0].data) 

          let collectionCopy = []
          let collectionToProcess = -1;
          if(state.aquariums && state.aquariums.length > 0)
          {
            for(var i=0; i<state.aquariums.length;i++)
            {
              if(state.aquariums[i].Id == responses[1].data.Id)
              {
                  collectionCopy[i] = responses[1].data;
                  collectionToProcess = i;
              }
              else
              {
                collectionCopy[i] = state.aquariums[i];
              }
            }
          }
          else
          {
            collectionCopy[0] = responses[1].data;
            collectionToProcess = 0;
          }

          if(collectionToProcess>=0)
          {

            console.log("handling old sample " + collectionToProcess);

            for(var j=0; j<collectionCopy[collectionToProcess].Devices.length; j++)
            {
              let device = collectionCopy[collectionToProcess].Devices[j]
              if(device.Samples)
              {
                let firstNewSample = moment(device.Samples[0].TimeStamp);
                let oldItemsToAdd = [];
                let count = 0;

                console.log("firstNewSample " + firstNewSample.format("X"));
                console.log("startSample " + startSample.format("X"));

                if(firstNewSample>startSample)
                {
                  if(state.aquariums && state.aquariums.length>0)
                  {
                    // fill from state
                    for(var d =0; d<state.aquariums[collectionToProcess].Devices.length; d++)
                    {
                        if(state.aquariums[collectionToProcess].Devices[d].Id == device.Id && state.aquariums[collectionToProcess].Devices[d].Samples)
                        {
                          for(var s = state.aquariums[collectionToProcess].Devices[d].Samples.length-1; s>=0; s--)
                          {
                            let z = moment(state.aquariums[collectionToProcess].Devices[d].Samples[s].TimeStamp);
                            if(z < startSample)
                              break;

                            if(z < firstNewSample)
                            {
                              oldItemsToAdd[count] = state.aquariums[collectionToProcess].Devices[d].Samples[s];
                              count++;
                            }
                          }
                          break;
                        }
                    }
                  }
                }
                //console.log("oldItemsToAdd.length " + oldItemsToAdd.length);

                console.log("zzzz");


                if(oldItemsToAdd.length>0)                      
                {
                  oldItemsToAdd.reverse();
                  for(var newItemCounter = 0; newItemCounter<device.Samples.length; newItemCounter++)
                  {
                    oldItemsToAdd[count] = device.Samples[newItemCounter];
                    count++;
                  }
                  device.Samples = oldItemsToAdd;
                }


                console.log("computing graphData");

                let retVal = {
                    labels:[],
                    datasets: []
                }
                let counter = 0;
                let datasetIndex = []
                for (let index = 0; index < responses[0].data.length; index++) 
                {
                    const element = responses[0].data[index];      
                    if(element.Id == "1"  )
                    {
                        retVal.datasets[counter] = {
                            label: element.Description,
                            borderWidth: 1,
                            borderColor: '#B0BEC5',
                            fill: false,
                            pointRadius: 0,
                            data: []
                        };
                        datasetIndex[element.Id] = counter;
                        counter++;
    
                    }
                    else if(element.Id == "2" )
                    {
                        retVal.datasets[counter] = {
                            label: element.Description,
                            borderWidth: 1,
                            borderColor: '#90CAF9',
                            fill: false,
                            pointRadius: 0,
                            data: []
                        };
                        datasetIndex[element.Id] = counter;
                        counter++;
    
                    }
                    else if(element.Id == "6" )
                    {
                        retVal.datasets[counter] = {
                            label: element.Description,
                            borderWidth: 1,
                            borderColor: '#FFCC8077',
                            backgroundColor: '#FFCC8011',
                            fill: 'origin',
                            pointRadius: 0,
                            data: []
                        };
                        datasetIndex[element.Id] = counter;
                        counter++;
    
                    }
                    else if(element.Id == "7" )
                    {
                        retVal.datasets[counter] = {
                            label: element.Description,
                            borderWidth: 1,
                            borderColor: '#CE93D877',
                            backgroundColor: '#CE93D811',
                            fill: 'origin',
                            pointRadius: 0,
                            data: []
                        };
                        datasetIndex[element.Id] = counter;
                        counter++;
    
                    }
                }
                console.log("computing graphData 2");
    
                for(var i=0; i<device.Samples.length; i++)
                {
                    retVal.labels[i] = moment(device.Samples[i].TimeStamp).local();//.format('HH:mm:ss');
                    for(var j=0; j<device.Samples[i].Value.length; j++)
                    {
                        if(datasetIndex[device.Samples[i].Value[j].PropertyId]>=0)
                        {
                            retVal.datasets[datasetIndex[device.Samples[i].Value[j].PropertyId]].data[i] = device.Samples[i].Value[j].Value;
                        }
                    }
                }
    
                device.GraphDataCollection = retVal;

              }      
            }
          }

          commit("setAquariums", collectionCopy)

          if(responses[1].data.AssociationPinCode)
          {
            setTimeout(()=>dispatch("reloadAssociationPinCode", aquaId),5000)
          }
          commit("setLoading", false)}))
      .catch(errors => {
        commit("setLoading", false)
        console.log(errors)
        if (errors.response && errors.response.status === 401) {
          commit("refreshSession");
        } 
      })
    },    

    reloadAssociationPinCode({commit, dispatch, state}, aquaId)
    {
      commit("refreshSession");

      var jwtToken = state.cognitoAuth.getSignInUserSession().getIdToken().jwtToken;
      var requestData = {
          headers: {
              'Authorization': 'Bearer '+ jwtToken
          }
      }

      var before
      for(var i=0; i<state.aquariums.length;i++)
      {
        if(state.aquariums[i].Id == aquaId)
        {
          before = state.aquariums[i].AssociationPinCode;
          break;
        }
      }


      axios.get("https://api.reefmon.com/aquariums/" + aquaId, requestData).then(response => { 

        if(response.data.AssociationPinCode)
        {
          setTimeout(()=>dispatch("reloadAssociationPinCode", aquaId),10000)
        }
        else
        {
          if(before)
          {
            dispatch("reloadAquarium", aquaId).then(()=> {
              if(!response.data.PendingNewDevice) commit("setWarning", "The system did not detect any device requesting association using the provided pincode. Please, verify device connectivity and try again.")
            });
          }
        }

      },
      error =>{
        console.log(error)
        if (error.response && error.response.status === 401) {
          commit("refreshSession");
        } 
      }) 
    },

    cancelPendingNewDevice({commit, state},aqua)
    {
      commit("setLoading", true)
      commit("refreshSession");

      var jwtToken = state.cognitoAuth.getSignInUserSession().getIdToken().jwtToken;
      var requestData = {
          headers: {
              'Authorization': 'Bearer '+ jwtToken
          }
      }
      axios.delete("https://api.reefmon.com/aquariums/" + aqua.Id + "/pendingnewdevice/" + aqua.PendingNewDevice.Id, requestData).then(response => {   
        this.dispatch('reloadAquarium', aqua.Id)       
      },
      error =>{
        commit("setLoading", false)
        console.log(error)
        if (error.response && error.response.status === 401) {
          commit("refreshSession");
        } 
      }) 
    },

    confirmPendingNewDevice({commit, state},aqua)
    {
      commit("setLoading", true)
      commit("refreshSession");

      var jwtToken = state.cognitoAuth.getSignInUserSession().getIdToken().jwtToken;
      var requestData = {
          headers: {
              'Authorization': 'Bearer '+ jwtToken
          }
      }
      axios.post("https://api.reefmon.com/aquariums/" + aqua.Id + "/pendingnewdevice/" + aqua.PendingNewDevice.Id, null, requestData).then(response => {   
        this.dispatch('reloadAquarium', aqua.Id)       
      },
      error =>{
        commit("setLoading", false)
        console.log(error)
        if (error.response) {
          if(error.response.status === 401)
          {
            commit("refreshSession");
          }
          else if(error.response.status === 409)
          {
            commit("setWarning", "It seems that the device you are trying to add is already associated to an aquarium. Please, remove that association before trying again.");
            this.dispatch("cancelPendingNewDevice", aqua)
          }

        } 
      }) 
    },

    clearWarning({commit, state})
    {
      commit("setWarning", null)
    }

  },
  modules: {
  }
})

export default store;