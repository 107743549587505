/* eslint-disable */
import { createRouter, createWebHistory  } from 'vue-router'
import HomeItem from '@/components/HomeItem'
import SettingsItem from '@/components/SettingsItem'
import AquariumItem from '@/components/AquariumItem'
import ErrorItem from '@/components/ErrorItem'
import LogoutSuccess from '@/components/LogoutSuccess';
import store from '../store';


function requireAuth(to, from, next)
{
  store.dispatch("loginOrRefreshSession");
  next();
}

const router = createRouter({
  history:createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomeItem,
      beforeEnter: requireAuth
    },
    {
      path: '/settings',
      name: 'Settings',
      component: SettingsItem,
      beforeEnter: requireAuth
    },
    {
      path: '/aquariums',
      name: 'Aquariums',
      component: HomeItem,
      beforeEnter: requireAuth
    },
    {
      path: '/aquariums/:id',
      name: 'Aquarium',
      component: AquariumItem,
      beforeEnter: requireAuth
    },
    {
       path: '/login', beforeEnter(to, from, next){
        store.dispatch("loginOrRefreshSession");
      }
    },
    {
      name: 'BackFromAuth',
      path: '/login/oauth2/code/cognito', beforeEnter(to, from, next){
        var currUrl = window.location.href;    
        //auth.auth.parseCognitoWebResponse(currUrl);
        store.commit("parseCognitoWebResponse", currUrl);
        next();
      }
    },
    {
      name: 'Logout',
      path: '/logout', component: LogoutSuccess,  beforeEnter(to, from, next){
        store.commit("logout");
        //auth.logout();
        //store.commit("reset");
        next();
      }

    },
    {
      name: 'Error',
      path: '/error', 
      component: ErrorItem
    }
  ]
})

export default router;